import React, { useEffect } from 'react';
import CoinItem from './RenderCoin';
import Coin from '../routing/Coin';
import Spinner from './Spinner';
import { Link } from 'react-router-dom';
import '../index.css';

function Coins({ coins }) {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://banner.admedia.network/banner?zoneid=l2rrPHzJPaquH';
        script.async = true;
        const newTabScript = document.createElement('script');
        script.src = 'https://newtab.admedia.network/newtab?zoneid=yX6HUW4ozZtyA'
        script.async = true;
        document.body.appendChild(newTabScript)
        const specificDiv = document.getElementById('specific-div'); // Replace with the actual ID of your specific div
        specificDiv.appendChild(script);

        return () => {
            specificDiv.removeChild(script);
        };
    }, []);


    const handleLinkClick = (event) => {
        // Prevent the default behavior of the link
        event.preventDefault();

        // Open the link in a new tab
        window.open('https://redirect.admedia.network/directlink?zoneid=f1Teiejz3tN8q', '_blank');
    };

    return (
        <div>
            <div className="my-5">
                <div className="">
                    <div id="specific-div" className='mx-6'></div>
                </div>
            </div>
            <div className="flex">
                <div id="longDiv"></div>
                <div className='max-w-screen-lg m-auto w-full'>
                    <div>
                        <div className='flex flex-row justify-between items-center bg-gray-900 rounded-lg font-bold mx-4 my-8 py-3 px-4 shadow-sm'>
                            <p>#</p>
                            <p className='-ml-16'>Coin</p>
                            <p>Price</p>
                            <p>24h</p>
                            <p className='hide-mobile'>Volume</p>
                            <p className='hide-mobile'>Mkt Cap</p>
                        </div>
                        {console.log(coins)}
                        {coins === undefined || coins.length === 0 ? <Spinner /> : coins.map(coin => {
                            return (
                                <Link to={`/coin/${coin.id}`} onClick={handleLinkClick} element={<Coin />} key={coin.id}>
                                    <CoinItem coin={coin} />
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Coins;
