import React from 'react'
import { BsFillPhoneFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'

//Navbar
function NavBar() {
    return (
        <Link to='/'>
            <div className='flex justify-center text-center mt-10'>
                <BsFillPhoneFill className='text-4xl text-purple-300 mx-2' />
                <h1 className='text-xl'> Crypto <span className='bg-purple text-center text-xl'> Phone </span></h1>
            </div>
        </Link>

    )
}

export default NavBar
